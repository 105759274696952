import React from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
// eslint-disable-next-line react/prop-types
export default function Login({ callback }) {
  const [error, setError] = React.useState(false);
  return (
    <>
      <Grid xs='auto' sm='auto' id='test'>
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            try {
              await axios.post(
                `${process.env.REACT_APP_SERVER_HOST}/login`,
                {},
                {
                  headers: { Authorization: `Bearer ${credentialResponse.credential}` },
                },
              );
              callback(true, credentialResponse.credential);
            } catch (Error) {
              setError(true);
              callback(false, null);
            }
          }}
          onError={async () => {
            setError(true);
            callback(false, null);
          }}
          useOneTap
          use_fedcm_for_prompt
          auto_select
          theme='filled_black'
          prompt_parent_id='oneTap'
        />
      </Grid>
      <Grid id='oneTap'>
        {error && (
          <Alert severity='error' variant='outlined'>
            Login Failed
          </Alert>
        )}
      </Grid>
    </>
  );
}
